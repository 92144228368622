.profile {
    @apply space-y-2 flex flex-col items-center justify-center text-center text-blue-400 fill-blue-400 transition-colors duration-300;
}

.profile:hover {
    @apply text-yellow-300 fill-yellow-300;
}

.icon_size{
    @apply w-8 lg:w-12 xl:w-16;
}

@keyframes fade_in_left {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate_fade_in_left {
    animation: fade_in_left 1s ease-in-out both 1;
}


.animate_parent:nth-child(1) {
    animation-delay: 1.25s;
}

.animate_parent:nth-child(2) {
    animation-delay: 1s;
}

.animate_parent:nth-child(3) {
    animation-delay: 0.75s;
}

.profile_text{
    @apply text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl;
}