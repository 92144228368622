/* This css file is for all global classes in componenets */

@tailwind utilities;

.section_heading {
    @apply tracking-wider text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-extrabold text-my-yellow;
}

.sub_section_heading {
    @apply tracking-wider leading-tight md:leading-normal text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold text-cyan-400;
}

.base_tag {
    @apply uppercase text-cyan-500 text-xs md:text-sm lg:text-base xl:text-lg tracking-wide font-semibold;
}

.tag {
    @apply base_tag w-fit px-2 rounded-lg border-2 border-cyan-500;
}

.tag_container {
    @apply flex flex-wrap justify-start gap-y-2 gap-x-2;
}

.description {
    @apply text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl tracking-tight text-justify text-slate-50;
}


.section_spacing {
    @apply space-y-10 md:space-y-16;
}

.sub_section_spacing {
    @apply px-4 md:px-10 space-y-8 md:space-y-16;
}

.button {
    @apply cursor-pointer font-semibold text-sm md:text-base lg:text-lg xl:text-2xl w-fit text-black px-4 py-1 bg-blue-500 hover:bg-green-500 rounded-lg
}

.numbering {
    @apply text-green-500;
}

.bg_gradient {
    @apply fixed inset-0 bg-gradient-to-b from-my-blue to-my-blue-2;
    z-index: -1;
}

.pagePadding{
    @apply px-4 md:px-8 lg:px-24 xl:px-40 2xl:px-60;
}