@tailwind utilities;


.detail_text{
    @apply text-xs md:text-sm lg:text-base xl:text-lg;
}

.icon{
    @apply w-3 h-3 md:w-4 md:h-4 fill-none stroke-white;
}

.calender_icon{
    @apply -mt-1 md:mt-1 lg:mt-2 xl:mt-3 w-3 h-3 md:w-4 md:h-4 fill-blue-400;
}